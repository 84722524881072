import React from 'react'

import { Layout } from '../components/shared/Layout';
import { Privacy } from '../components/privacy/Privacy';
import { SEO } from '../components/seo';

export default function AboutPage({ location }) {
  return (
    <Layout location={location}>
      <SEO title="Privacy" />
      <Privacy />
    </Layout>
  )
}
