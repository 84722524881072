import React from 'react';

import { Container } from '../../shared/Container';

export function Privacy() {
  return (
    <Container className="px-5 md:px-0">
      <div className="flex items-center justify-center my-10">
        <span className="hidden md:inline-block sm:h-1 mr-2 mt-1 w-20 bg-secondary" />
        <h1 className="text-3xl flex-shrink-0">Cookies usage</h1>
        <span className="hidden md:inline-block sm:h-1 ml-2 mt-1 w-full bg-secondary" />
      </div>
      <div className="mb-16">
        <h2 className="text-2xl">Required cookies</h2>
        <p className="mb-10">
          Some cookies are required to provide core functionality. The website won't function
          properly without these cookies and they are enabled by default and cannot be disabled.
        </p>
        <div className="overflow-y-auto">
          <table className="w-full">
            <thead className="text-left bg-main text-white">
              <th className="px-5 py-1">Name</th>
              <th className="px-5 py-1">Purpose</th>
              <th className="px-5 py-1">Expires</th>
            </thead>
            <tr>
              <td className="px-5 py-1">cookiehub</td>
              <td className="px-5 py-1">
                Used by CookieHub to store information about whether visitors have given or declined
                the use of cookie categories used on the site.
              </td>
              <td className="px-5 py-1">1 year</td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <h2 className="text-2xl">Analytical cookies</h2>
        <p className="mb-10">
          Analytical cookies help us improve our website by collecting and reporting information on
          its usage.
        </p>
        <div className="overflow-y-auto">
          <table className="w-full">
            <thead className="text-left bg-main text-white">
              <th className="px-5 py-1">Name</th>
              <th className="px-5 py-1">Purpose</th>
              <th className="px-5 py-1">Expires</th>
            </thead>
            <tr>
              <td className="px-5 py-1">_ga</td>
              <td className="px-5 py-1">
                Used to distinguish unique users by assigning a randomly generated number as a client
                identifier.
              </td>
              <td className="px-5 py-1">2 years</td>
            </tr>
            <tr>
              <td className="px-5 py-1">_gid</td>
              <td className="px-5 py-1">
                Used to distinguish unique users by assigning a randomly generated number as a client
                identifier.
              </td>
              <td className="px-5 py-1">24 hours</td>
            </tr>
            <tr>
              <td className="px-5 py-1">_gat_gtag_UA_158069004_1</td>
              <td className="px-5 py-1">
                Used to throttle requests to Google Analytics and increase the efficiency of network
                calls.
              </td>
              <td className="px-5 py-1">1 hour</td>
            </tr>
          </table>
        </div>
      </div>
    </Container>
)
}
